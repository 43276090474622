/*
 * @Author: 罗洁 12914881+lj323@user.noreply.gitee.com
 * @Date: 2023-10-11 14:41:17
 * @LastEditors: 罗洁 12914881+lj323@user.noreply.gitee.com
 * @LastEditTime: 2024-08-13 23:15:14
 * @FilePath: \nc_noded:\web_Study\Vue-project\vue2+webpack\hm-shopping\src\api\address.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import request from '@/utils/request'
// 获取收货地址
export const getAddress = () => {
  return request.get('/address/list')
}
// 获取默认收货地址id
export const getdefaultId = () => {
  return request.get('/address/defaultId')
}

// 获取省市区
export const getregion = () => {
  return request.get('/region/tree')
}
// 添加收货地址
export const addAddress = (name, phone, region, detail) => {
  return request.post('/address/add', {
    form: {
      name,
      phone,
      region,
      detail
    }
  })
}

// 编辑收货地址
export const editAddress = (addressId, name, phone, region, detail) => {
  return request.post('/address/edit', {
    addressId,
    form: {
      name,
      phone,
      region,
      detail
    }
  })
}

// 删除收货地址
export const delAddress = (addressId) => {
  return request.post('/address/remove', {
    addressId
  })
}

// 设置默认地址
export const defaultAddress = (addressId) => {
  return request.post('/address/setDefault', {
    addressId
  })
}
