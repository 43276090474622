import { getCartList, changeCount, delGoods } from '@/api/cart'
import { Toast } from 'vant'
export default {
  namespaced: true,
  state () {
    return {
      cartList: []
    }
  },
  mutations: {
    // 将购物车数据存储起来
    setCartList (state, newCartList) {
      state.cartList = newCartList
    },
    // 修改选中状态
    toggleCheck (state, goodsId) {
      const list = state.cartList.find(item => item.goods_id === goodsId)
      list.isChecked = !list.isChecked
    },
    // 全选控制小选
    toggleAllCheck (state, flag) {
      state.cartList.forEach(item => { item.isChecked = flag })
    },
    // 修改数量
    changeCount (state, { goodsId, goodsNum }) {
      const goods = state.cartList.find(item => item.goods_id === goodsId)
      goods.goods_num = goodsNum
    }
  },
  getters: {
    // 购物车商品总数
    countTotal (state) {
      return state.cartList.reduce((sum, item) => sum + item.goods_num, 0)
    },
    // 获取选中的商品序列
    selList (state) {
      return state.cartList.filter(item => item.isChecked)
    },
    // 对选中的商品总数求和
    setTotal (state, getters) {
      return getters.selList.reduce((sum, item) => sum + item.goods_num, 0)
    },
    // 对于选中的商品价格求和
    selPrice (state, getters) {
      return getters.selList.reduce((sum, item) => sum + item.goods_num * item.goods.goods_price_min, 0).toFixed(2)
    },
    // 获取当前全选按钮状态 小选控制全选
    isAllChecked (state) {
      return state.cartList.every(item => item.isChecked)
    }
  },
  actions: {
    // 获取购物车数据
    async getCartAction (context) {
      const { data } = await getCartList()
      data.list.forEach(item => {
        item.isChecked = true
      })
      context.commit('setCartList', data.list)
      console.log(data)
    },
    // 页面更新购物车数据
    async changeCountAction (context, obj) {
      const { goodsId, goodsNum, goodsSkuId } = obj
      context.commit('changeCount', {
        goodsId,
        goodsNum
      })
      //   服务器修改数据
      await changeCount(goodsId, goodsNum, goodsSkuId)
    },
    // 删除购物车数据
    async delSelect (context) {
      const selCartList = context.getters.selList
      const selcartid = selCartList.map(item => item.id)
      await delGoods(selcartid)
      Toast('删除成功')
      console.log(selcartid)
      //   重新拉取数据
      context.dispatch('getCartAction')
    }
  }
}
