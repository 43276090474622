
import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout'

import Home from '@/views/layout/home'
import Category from '@/views/layout/category'
import Cart from '@/views/layout/cart'
import User from '@/views/layout/user'

import store from '@/store'

const Login = () => import('@/views/login')
const Search = () => import('@/views/search')
const SearchList = () => import('@/views/search/searchlist')
const Pay = () => import('@/views/pay')
const proDetail = () => import('@/views/goodsdetail')
const proComment = () => import('@/views/goodsdetail/detail.vue')

const Myorder = () => import('@/views/myorder')
const OrderDetail = () => import('@/views/myorder/detail')
const Address = () => import('@/views/address')
const AddressEdit = () => import('@/views/address/addressedit.vue')

Vue.use(VueRouter)

const routes = [
  { path: '/login', component: Login },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      { path: '/home', component: Home },
      { path: '/category', component: Category },
      { path: '/cart', component: Cart },
      { path: '/user', component: User }
    ]
  },
  { path: '/search', component: Search },
  { path: '/searchlist', component: SearchList },
  { path: '/pay', component: Pay },
  { path: '/prodetail/:id', component: proDetail },
  { path: '/procomment', component: proComment },
  { path: '/myorder', component: Myorder },
  { path: '/myorder/detail', component: OrderDetail },
  { path: '/myaddress', component: Address },
  { path: '/address/edit', component: AddressEdit }

]

const router = new VueRouter({
  routes
})
// 存储需要权限的路由页面
const authUrl = ['/pay', '/myorder', '/myorder/detail']
router.beforeEach((to, from, next) => {
  // 1. to往哪里去， 到哪去的路由信息对象
  // 2. from 从哪里来， 从哪来的路由信息对象
  // 3. next() 是否放行
  // 如果next()调用，就是放行
  // next(路径) 拦截到某个路径页面
  const token = store.getters.token
  if (!authUrl.includes(to.path)) {
    // 去到的页面不需要权限
    next()
    return
  }
  // 去到的页面需要权限
  if (token) {
    // token存在就放行
    next()
  } else {
    // 不存在就跳转至登录界面
    next('/login')
  }

  console.log(to, from, next)
})
export default router
