<template>
  <div class="count_box">
    <button class="minus" @click="subCount">-</button>
    <input type="text" class="inp" :value="value" @change="handleCount"/>
    <button class="add" @click="addCount">+</button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1
    }
  },
  methods: {
    addCount () {
      this.$emit('input', this.value + 1)
    },
    subCount () {
      if (this.value <= 1) {
        return
      }
      this.$emit('input', this.value - 1)
    },
    handleCount (e) {
      const num = +e.target.value
      //   console.log(e.target.value)
      console.log(num)
      if (isNaN(num) || num < 1) {
        // 输入非法的数字字符将设置为上一次有效的数字
        e.target.value = this.value
        return
      }
      this.$emit('input', num)
    }
  }
}
</script>

<style lang="less" scoped>
.count_box{
    width: 110px;
    display: flex;
    .inp{
        width: 40px;
        outline: none;
        border: none;
        margin: 0 5px;
        background-color: #efefef;
        text-align: center;
    }
    .minus,.add{
        width: 30px;
        height: 30px;
        outline: none;
        border: none;
        background-color: #efefef;
    }
}
</style>
