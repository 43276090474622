/*
 * @Author: 罗洁 12914881+lj323@user.noreply.gitee.com
 * @Date: 2023-10-08 19:44:33
 * @LastEditors: 罗洁 12914881+lj323@user.noreply.gitee.com
 * @LastEditTime: 2024-08-08 23:01:59
 * @FilePath: \nc_noded:\web_Study\Vue-project\vue2+webpack\hm-shopping\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import cart from './modules/cart'
import address from './modules/address'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
    token (state) {
      console.log('token', state)
      return state.user.userInfo.token
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    cart,
    address
  }
})
