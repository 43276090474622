import Vue from 'vue'
import { Button, Tabbar, TabbarItem, NavBar, Toast, Swipe, SwipeItem, Search, Grid, GridItem, Icon, Lazyload, Rate, ActionSheet, Dialog, Checkbox, Tab, Tabs, Cell, CellGroup, AddressList, AddressEdit, Area, List } from 'vant'
Vue.use(Tabbar)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Checkbox)
Vue.use(Dialog)
Vue.use(Lazyload)
Vue.use(ActionSheet)
Vue.use(Swipe)
Vue.use(Rate)
Vue.use(Icon)
Vue.use(SwipeItem)
Vue.use(TabbarItem)
Vue.use(Button)
Vue.use(NavBar)
Vue.use(Toast)
Vue.use(Search)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(AddressList)
Vue.use(AddressEdit)
Vue.use(Area)
Vue.use(List)
