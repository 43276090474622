import { getInfo, setInfo } from '@/utils/storage'
export default {
  namespaced: true,
  state () {
    return {
      userInfo: getInfo()
    }
  },
  mutations: {
    // 存储用户信息 用户id和token值
    setUserInfo (state, obj) {
      // 将数据存入本地仓库state中
      state.userInfo = obj
      // 将用户信息存入本地存储中
      setInfo(obj)
    }
  },
  getters: {},
  actions: {
    // 退出登录
    logout (context) {
      context.commit('setUserInfo', [])
      // 第二个是要传递的数据
      context.commit('cart/setCartList', [], { root: true })
    }
  }
}
