/*
 * @Author: 罗洁 lj323@user.noreply.gitee.com
 * @Date: 2023-10-08 20:52:56
 * @LastEditors: 罗洁 12914881+lj323@user.noreply.gitee.com
 * @LastEditTime: 2024-08-17 13:42:28
 * @FilePath: \hm-shopping\src\utils\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios'
import { Toast } from 'vant'
import store from '@/store/index'
const request = axios.create({
  // baseURL: 'http://cba.itlike.com/public/index.php?s=/api/',
  baseURL: 'https://smart-shop.itheima.net/index.php?s=/api',
  timeout: 10000
})

// 添加请求拦截器
request.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // 自定义加载图标
  Toast.loading({
    message: '加载中...',
    forbidClick: true,
    loadingType: 'spinner',
    duration: 0
  })

  const token = store.getters.token
  // 如果存在token需要在请求头统一加上两个参数
  // 请求拦截器中，统一携带 token
  if (token) {
    config.headers['Access-Token'] = token
  }
  config.headers.platform = 'H5'

  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
request.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  // 服务器发送给浏览器的相应信息
  const res = response.data
  if (res.status !== 200) {
    console.log(res)
    // Toast(res.message)
    Toast.clear()
    return Promise.reject(res.message)
  } else {
    Toast.clear()
  }
  return res
}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  return Promise.reject(error)
})
export default request
