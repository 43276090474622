/*
 * @Author: 罗洁 12914881+lj323@user.noreply.gitee.com
 * @Date: 2023-10-11 14:45:56
 * @LastEditors: 罗洁 12914881+lj323@user.noreply.gitee.com
 * @LastEditTime: 2024-08-13 16:17:56
 * @FilePath: \nc_noded:\web_Study\Vue-project\vue2+webpack\hm-shopping\src\store\modules\address.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { getAddress, getdefaultId } from '@/api/address'
export default {
  namespaced: true,
  state: {
    addressList: [],
    addressInfo: [],
    // 默认收货地址的id
    defaultId: 0
  },
  mutations: {
    // 存储地址信息
    setAddress (state, addreList) {
      state.addressList = addreList
    },
    // 存储地址信息
    setAddressinfo (state, addreList) {
      state.addressInfo = addreList
    },
    // 获取默认收货地址
    getdefaultId (state, addressid) {
      state.defaultId = addressid
    },
    // 获取默认收货地址
    setdefaultId (state, addressid) {
      state.defaultId = addressid
    }
  },
  getters: {
    // 筛选默认地址数据
    getdefault (state) {
      return state.addressList.find(item => item.address_id === state.defaultId)
    }
  },
  actions: {
    // 获取地址数据
    async getaddress (context) {
      const { data } = await getAddress()
      console.log(data)
      const { data: { defaultId } } = await getdefaultId()
      context.commit('getdefaultId', defaultId)

      console.log(data.list)

      // 重新定义地址数据
      const addressList = []
      data.list.forEach((item, index) => {
        const obj = {}
        obj.id = item.address_id
        obj.tel = item.phone
        obj.address = item.detail
        obj.name = item.name
        obj.addressDetail = item.detail
        obj.cityinfo = item.region.province + ' ' + item.region.city + ' ' + item.region.region

        if (item.address_id === defaultId) {
          obj.isDefault = true
        }
        addressList.push(obj)
      })

      context.commit('setAddress', data.list)
      // 经过筛选之后的地址
      context.commit('setAddressinfo', addressList)
    }

  }
}
