/*
 * @Author: 罗洁 lj323@user.noreply.gitee.com
 * @Date: 2023-10-12 09:57:52
 * @LastEditors: 罗洁 lj323@user.noreply.gitee.com
 * @LastEditTime: 2023-10-12 09:58:51
 * @FilePath: \hm-shopping\src\api\user.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
export const getUserInfo = () => {
  return request.get('/user/info')
}
