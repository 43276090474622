/*
 * @Author: 罗洁 lj323@user.noreply.gitee.com
 * @Date: 2023-10-10 16:35:13
 * @LastEditors: 罗洁 lj323@user.noreply.gitee.com
 * @LastEditTime: 2023-10-11 08:50:07
 * @FilePath: \hm-shopping\src\api\cart.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
// 添加购物车
export const addCart = (goodsId, goodsNum, goodsSkuId) => {
  return request.post('/cart/add', {
    goodsId,
    goodsNum,
    goodsSkuId
  })
}
// 获取购物车数据
export const getCartList = () => {
  return request.get('/cart/list')
}

// 修改购物车商品数量
export const changeCount = (goodsId, goodsNum, goodsSkuId) => {
  return request.post('/cart/update', {
    goodsId,
    goodsNum,
    goodsSkuId
  })
}

// 删除购物车商品
export const delGoods = (cartIds) => {
  return request.post('/cart/clear', {
    cartIds
  })
}
